<template>
  <div id="app">
    <router-view :key="$route.query.login_type ? $route.path : $route.fullPath" />
    <YorkbbsMobileAlert></YorkbbsMobileAlert>
  </div>
</template>
<script>
import { getUserMixin } from '@/utils/user.mixin';
import { ExternalLink } from '@/utils/ExternalLink';
import YorkbbsMobileAlert from '@/common/alert-mobile.vue';
export default {
  name: 'App',
  mixins: [getUserMixin],
  components: { YorkbbsMobileAlert },
  mounted() {
    const externalLink = new ExternalLink();
    externalLink.addListener();
    this.$once('hook:beforeDestroy', () => {
      externalLink.removeListener();
    });
  },
};
</script>
