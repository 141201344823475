import { Router, scrollBehavior } from '@/utils/router.js';
import { setHtmlMeta } from '@/utils/utils';
import { getTokenFromCookie } from '@/utils/cookies';

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: { name: 'Login' },
      meta: { name: '首页' },
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "core_account" */ './login/login.vue'),
      meta: { name: '登录' },
    },
    {
      path: '/regist',
      name: 'Regist',
      redirect: { name: 'RegistPhone' },
      meta: { name: '注册' },
    },
    {
      path: '/regist/phone',
      name: 'RegistPhone',
      component: () => import(/* webpackChunkName: "core_account" */ './regist-phone/regist-phone.vue'),
      meta: { name: '手机号码注册' },
    },
    {
      path: '/regist/email',
      name: 'RegistEmail',
      component: () => import(/* webpackChunkName: "core_account" */ './regist-email/regist-email.vue'),
      meta: { name: '邮箱注册' },
    },
    {
      path: '/reset',
      name: 'Reset',
      redirect: { name: 'ResetPhone' },
      meta: { name: '重置密码' },
    },
    {
      path: '/reset/phone',
      name: 'ResetPhone',
      component: () => import(/* webpackChunkName: "core_account" */ './reset-phone/reset-phone.vue'),
      meta: { name: '手机号码重置密码' },
    },
    {
      path: '/reset/email',
      name: 'ResetEmail',
      component: () => import(/* webpackChunkName: "core_account" */ './reset-email/reset-email.vue'),
      meta: { name: '邮箱重置密码' },
    },
    {
      path: '/auth',
      name: 'Auth',
      redirect: { name: 'AuthPhone' },
      meta: { name: '认证' },
    },
    {
      path: '/auth/phone',
      name: 'AuthPhone',
      component: () => import(/* webpackChunkName: "core_account" */ './auth-phone/auth-phone.vue'),
      meta: { name: '手机号码认证' },
    },
    {
      path: '/test/dialog',
      name: 'TestDialog',
      component: () => import(/* webpackChunkName: "core_account" */ './test-dialog/test-dialog.vue'),
      meta: { name: '对话框测试' },
    },
    {
      path: '/websafe',
      name: 'WebSafe',
      component: () => import(/* webpackChunkName: "core_account" */ '@/common/websafe.vue'),
    },
    {
      path: '/404',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "core_account" */ '@/common/404/index.vue'),
      meta: { desc: '404' },
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  // 这里只能用本地token进行判断是否登录，否则刷新页面会出现问题
  const token = getTokenFromCookie();
  // if (to.name == 'Login' && token) {
  //   const redirect = to.query.redirect || hostBuild('/', 'www');
  //   location.replace(redirect);
  //   return next(false);
  // }

  if (to.name == 'RegistPhone') {
    setHtmlMeta({
      title: '用户注册 - 约克论坛',
      keywords: '',
      description: '约克论坛（yorkbbs.ca）的用户注册页面，新会员注册仅需要Email或手机号。',
    });
  } else if (to.name == 'RegistEmail') {
    setHtmlMeta({
      title: '用户注册 - 约克论坛',
      keywords: '',
      description: '约克论坛（yorkbbs.ca）的用户注册页面，新会员注册仅需要Email或手机号。',
    });
  } else if (to.name == 'Login') {
    setHtmlMeta({
      title: '用户登陆 - 约克论坛',
      keywords: '',
      description: '约克论坛（yorkbbs.ca）的用户登陆页面，会员仅需要Email或手机号即可登陆。',
    });
  } else if (to.name == 'AuthPhone') {
    setHtmlMeta({ title: '手机认证 - 约克论坛', keywords: '', description: '' });
  } else if (to.name == 'ResetPhone') {
    setHtmlMeta({ title: '手机重置密码 - 约克论坛', keywords: '', description: '' });
  } else if (to.name == 'ResetEmail') {
    setHtmlMeta({ title: 'Email重置密码 - 约克论坛', keywords: '', description: '' });
  } else if (to.name == 'WebSafe') {
    setHtmlMeta({ title: '外链 - 约克论坛', keywords: '', description: '' });
  } else if (to.name == 'NotFound') {
    setHtmlMeta({ title: '404 - 约克论坛', keywords: '', description: '' });
  } else {
    setHtmlMeta({ title: '', keywords: '', description: '' });
  }

  if (to.name == 'NotFound' && !to.query.from) return next({ name: 'NotFound', query: { from: location.href } });

  next();
});

export { router };
